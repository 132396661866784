<template>
    <component v-if="block.properties.show_title" :is="block.properties.show_title" class="text-center mb-0 block-title">
        <div v-html="block.name"></div>
    </component>
</template>

<script>
export default {
    props: {
        title: String,
        block: Object,
    }
};
</script>
