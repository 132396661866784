<template>
    <div class="container">
        <BlockTitle :block="block"/>
        <div class="row">
            <div v-for="item in useHomepageStore().contents" :class="`col-12 col-md-${12/block.properties.grid||1} col-sm-${2*12/block.properties.grid||1}`">
                <article class="cmp_cb_li_in">
                    <div class="card content mt-4">
                        <h3 class="mb-3 mt-2" v-html="item.name"></h3>
                        <div v-html="item.description"></div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</template>

<script setup>
import BlockTitle from './BlockTitle.vue';

const props = defineProps({
    block: Object,
});
</script>

<script>

export default {
    components: {BlockTitle},
};
</script>
