<template>
    <div>
        <template v-for="block in useHomepageStore().blocks">
            <div class="block-container" :class="block.properties.container?'container-xxl':'',block.properties.background, block.properties.padding_top, block.properties.padding_bottom, block.properties.extra_class">
                <div class="page-block component_content_block__default" :class="`block-${block.id} block-${block.properties.type}`">
                    <component :is="block.properties.type" :block="block"></component>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
import top_seller from './Blocks/TopSeller.vue';
import separator from './Blocks/Separator.vue';
import mints from './Blocks/Mints.vue';
import content from './Blocks/Content.vue';
import categories from './Blocks/Categories.vue';
import banners from './Blocks/Banners.vue';

// defineOgImage({
//     url: 'https://stonexbullion.dev/uploads/site_settings/logo.png'
// })
</script>


<script>
export default {
    components: {
        top_seller,
        separator,
        mints,
        content,
        categories,
        banners,
    },
    computed: {},
};
</script>
