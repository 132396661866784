<template>
    <div :class="block.properties.style || 'navy-line' "></div>
</template>

<script>
export default {
    props: {
        title: String,
        block: Object,
    },
    methods: {
    }
};
</script>
